<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @submit="handleSubmit" :form="form">
        <a-row :gutter="48">
          <a-col :md="8" :sm="24">
            <a-form-item label="用户类型">
              <a-select placeholder="请选择" v-decorator="['user_type', { initialValue: queryParam.user_type }]">
                <a-select-option value="">全部</a-select-option>
                <a-select-option value="user">用户</a-select-option>
                <a-select-option value="teacher">教员</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <a-form-item label="状态">
              <a-select placeholder="请选择" v-decorator="['status', { initialValue: queryParam.status }]">
                <a-select-option value="">全部</a-select-option>
                <a-select-option value="1">认证成功</a-select-option>
                <a-select-option value="2">认证中</a-select-option>
                <a-select-option value="3">开始认证</a-select-option>
                <a-select-option value="0">还未认证</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <span class="table-page-search-submitButtons">
              <a-button htmlType="submit" :loading="submitLoading" type="primary">查询</a-button>
              <a-button style="margin-left: 8px" @click="resetQuery">重置</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <a-table :columns="columns" :dataSource="tableData" :loading="tableLoading" rowKey="id">
      <span slot="user_type" slot-scope="text">
        {{ text | filterUserType }}
      </span>
      <span slot="status" slot-scope="text">
        {{ text | filterStatus }}
      </span>
      <span slot="update_time" slot-scope="text">
        {{ text | dayjs }}
      </span>
    </a-table>

  </a-card>
</template>

<script>
import merge from 'webpack-merge'

const columns = [
  {
    title: 'ID',
    dataIndex: 'id'
  },
  {
    title: '账户ID',
    dataIndex: 'auth_user_id'
  },
  {
    title: '用户类型',
    dataIndex: 'user_type',
    scopedSlots: { customRender: 'user_type' }
  },
  {
    title: '真实姓名',
    dataIndex: 'auth_real_name'
  },
  {
    title: '状态',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' }
  },
  {
    title: '错误信息',
    dataIndex: 'auth_fail'
  },
  {
    title: '更新时间',
    dataIndex: 'update_time',
    scopedSlots: { customRender: 'update_time' }
  }
]

export default {
  name: 'EmailList',
  data() {
    return {
      description: '列表使用场景：后台管理中的权限管理以及角色管理，可用于基于 RBAC 设计的角色权限控制，颗粒度细到每一个操作类型。',
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      // 高级搜索 展开/关闭
      advanced: false,
      // form
      form: this.$form.createForm(this),
      // 查询参数
      queryParam: {
        user_type: this.$route.query.user_type,
        status: (this.$route.query.status ? this.$route.query.status : '')
      },
      columns,
      tableData: [],
      emailType: [],
      submitLoading: false,
      tableLoading: false
    }
  },
  filters: {
    filterUserType(val) {
      if (val === 'user') {
        return '用户'
      } else {
        return '教员'
      }
    },
    filterStatus(val) {
      if (val === 1) {
        return '认证成功'
      } else if (val === 2) {
        return '认证中'
      } else if (val === 3) {
        return '开始认证'
      } else {
        return '还未认证'
      }
    }
  },
  created() {
    const parameter = this.queryParam
    this.loadData(parameter)
  },
  methods: {
    loadData(parameter) {
      this.tableLoading = true
      this.$http({
        url: 'admin/realname/list',
        method: 'get',
        params: parameter
      }).then(res => {
        this.tableLoading = false
        if (res.status === 'success') {
          this.tableData = res.data.list
        } else {
          this.notifacation.error({
            message: '系统繁忙,请刷新重试',
            description: ''
          })
        }
      })
    },
    handleSubmit(e) {
      // 重置查询条件，并重新请求
      e.preventDefault()
      const parameter = {}
      parameter.user_type = this.form.getFieldValue('user_type')
      parameter.status = this.form.getFieldValue('status')
      this.submitLoading = true
      this.loadData(parameter)
      this.submitLoading = false
      this.$router.push({
        query: merge(this.$route.query, parameter)
      })
    },
    resetQuery() {
      // 重置查询条件，并重新请求
      this.form.setFieldsValue({ 'user_type': '', status: '' })
      this.$router.push({
        query: merge({}, {})
      })
      this.submitLoading = true
      this.loadData()
      this.submitLoading = false
    }
  }
}
</script>
